import { Controller } from "@hotwired/stimulus"
import * as FilePond from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Connects to data-controller="photo-upload"
export default class extends Controller {
  static targets = ["files", "input"]
  connect() {
    FilePond.registerPlugin(FilePondPluginImagePreview);

    const name = this.inputTarget.name
    const filespaths = JSON.parse(this.inputTarget.dataset.files)
    const files = filespaths.map((filepath) => {return {source: filepath}})
    const pond = FilePond.create(this.inputTarget, {
      labelIdle: '<span class="text-muted">Déposez vos images ici ou cliquez pour <span class="filepond--label-action">Parcourir</span></span>',
      allowImagePreview: true,
      name: name,
      storeAsFile: true,
      files: files
    });
  }

}
