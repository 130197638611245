import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="profile-incomplete"
export default class extends Controller {
  connect() {
    const myModal = new bootstrap.Modal(document.getElementById('staticBackdropIncompleteProfile'))
    console.log(myModal);

    myModal.show()

  }
}
