import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["productToHide"];
  static values = {
    orderShopId: Number,
    productShopId: Number,
    productCount: Number
  }

  connect() {
    this.updateOrderShopIdFromLocalStorage();
    this.updateProductCountFromLocalStorage();
  }

  updateOrderShopIdFromLocalStorage() {
    const orderShopId = localStorage.getItem("orderShopId");
    this.orderShopIdValue = orderShopId ? parseInt(orderShopId, 10) : 0;
  }

  updateProductCountFromLocalStorage() {
    const productCount = localStorage.getItem("productCount");
    this.productCountValue = productCount ? parseInt(productCount, 10) : 0;
  }

  incrementProductCount() {
    this.productCountValue += 1;
    localStorage.setItem("orderShopId", this.orderShopIdValue.toString());
    localStorage.setItem("productCount", this.productCountValue.toString());
  }

  hideProduct(event) {
    this.updateOrderShopIdFromLocalStorage();
    this.updateProductCountFromLocalStorage();

    const { orderShopIdValue, productShopIdValue, productCountValue } = this;

    const card = event.currentTarget.closest('.product-card') || event.target.closest('.product-card');
    if (!card) return;

    if (orderShopIdValue === productShopIdValue || orderShopIdValue === 0) {
      if (productCountValue == 5) return;

      card.classList.add('d-none');
      this.incrementProductCount();

      if (orderShopIdValue === 0) {
        this.orderShopIdValue = productShopIdValue;
        this.updateTurboFrameAttribute(productShopIdValue);
        localStorage.setItem("orderShopId", this.orderShopIdValue.toString());
        localStorage.setItem("productCount", this.productCountValue.toString());
      }
    } else {
      const toast = card.querySelector('.toast');
      if (toast) {
        toast.classList.add('show');
        setTimeout(() => toast.classList.remove('show'), 6000);
      }
    }
    if (window.location.href.indexOf("cart") !== -1) {
      window.location.reload();
    }
  }

  updateTurboFrameAttribute(value) {
    const turboFrame = this.element.closest("turbo-frame");
    if (turboFrame) {
      turboFrame.setAttribute("data-hide-product-order-shop-id-value", value);
    }
  }

  decrementProductCount() {
    let productCount = parseInt(localStorage.getItem("productCount"), 10) || 0;
    if (productCount > 0) {
      productCount -= 1;
      localStorage.setItem("productCount", productCount.toString());

      if (productCount === 0) {
        localStorage.setItem("orderShopId", "0");
      }
    }
  }
}
