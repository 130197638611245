import LocalTime from "local-time"
import { localTimeFr } from "./local_time_translation"

const initLocalTime = () => {
  LocalTime.config.i18n["fr"] = localTimeFr()
  LocalTime.config.locale = "fr"
  LocalTime.start()
}

export { initLocalTime }
