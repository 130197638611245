import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["products", "pagination"];
  static values = {
    filters: Object
  };

  connect() {
    this.loading = false;
    this.endOfPagination = false;
    this.onScroll = this.onScroll.bind(this);
    window.addEventListener('scroll', this.onScroll);
  }

  disconnect() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    if (this.loading || this.endOfPagination) return;

    const nextPageLink = this.paginationTarget.querySelector(".next a");
    if (nextPageLink && this.isNearBottom()) {
      this.loadNextPage(nextPageLink.href);
    }
  }

  isNearBottom() {
    return window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;
  }

  loadNextPage(url) {
    this.loading = true;

    const urlObj = new URL(url, window.location.origin);

    // Ajouter les filtres à l'URL

    for (const [key, value] of Object.entries(this.filtersValue)) {
      if (value !== null && value !== undefined) {
        if (typeof value === 'object') {
          for (const [subKey, subValue] of Object.entries(value)) {
            urlObj.searchParams.append(`filter[${key}][${subKey}]`, subValue);
          }
        } else {
          urlObj.searchParams.append(`filter[${key}]`, value);
        }
      }
    }

    fetch(urlObj.toString(), {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(response => response.text())
      .then(html => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // Ajouter les nouveaux produits
        const newProducts = doc.querySelector('.products-grid').innerHTML;
        this.productsTarget.insertAdjacentHTML('beforeend', newProducts);

        // Mettre à jour la pagination
        const newPagination = doc.getElementById('pagination');

        if (newPagination && newPagination.querySelector('.disabled')) {
          this.endOfPagination = true;
          this.paginationTarget.innerHTML = "";
          window.removeEventListener('scroll', this.onScroll);
        } else {
          this.paginationTarget.innerHTML = newPagination.innerHTML;
        }

        this.loading = false;
      })
      .catch(error => {
        console.error('Erreur lors du chargement des produits :', error);
        this.loading = false;
      });
  }
}
