import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
  }

  close(event) {
    const button = event.target;
    const banner = button.closest("[data-controller='product-banner']");
    if (banner) {
      banner.style.display = "none";
    }
  }

  showDetails(event) {

    const button = event.target;
    const banner = button.closest("[data-controller='product-banner']");
    let detailsElement = banner.querySelector(".product-banner-details");
    let details = detailsElement.innerHTML;

    let modal = document.getElementById("detailsModal");
    if (modal) {
      modal.classList.remove('d-none')
      let modalContent = modal.querySelector(".banner-modal-content");
      if (modalContent) {
        modalContent.innerHTML = details;
      }
    }
  }

  hide() {
    let modal = document.querySelector("#detailsModal");
    if (modal) {
      modal.classList.add('d-none')
    }
  }
}
