import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "icon" ]

  toggle() {
    this.iconTargets.forEach((icon) => {
      icon.classList.toggle("d-none")
    })
    const path = window.location.href.slice(-9)
    if (path == "favorites") {
      setTimeout(function () {
        location.reload()
      }, 100);
    }
  }
}
