import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class extends Controller {
  static values = {
    datas: Array,
    typeOfChart: String,
    title: String,
    labelType: String,
    shopDepartments: Array
  }
  static targets = ["chart"];

  buttonIcon = null;

  connect() {
    if (this.hasChartTarget){
      this.displayChart(this.typeOfChartValue, this.titleValue, this.labelTypeValue, this.datasValue);
    }
  }

  displayChart(typeOfChart, title, labelType, datasParam) {
    let target = this.chartTarget;
    let labels;
    let datas = this.sumBy(datasParam, labelType);

    if (typeOfChart === "polarArea") {
      datas = Object.entries(datas).map(([label, value]) => ({ label, value }));
      labels = datas.map(entry => entry.label);
      datas = datas.map(entry => entry.value);
    }

    let options = {
      scales: {
        y: {
          beginAtZero: true
        }
      }
    };

    if (labelType === "department") {
      options.plugins = {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: (context) => `${context.dataset.label}: ${context.raw}`
          }
        }
      };
    }

    const chart = new Chart(target, {
      type: typeOfChart,
      data: {
        labels: labels,
        datasets: [{
          label: title,
          data: datas,
          borderWidth: 1
        }]
      },
      options: options
    });

    if (labelType === "department") {
      const colors = chart.data.datasets[0].backgroundColor;
      const values = chart.data.datasets[0].data;
      this.populateModal(labels, colors, values);
    }
  }

  sumBy(data, filter) {
    let resultFilteredByMonths = {
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      "6": 0,
      "7": 0,
      "8": 0,
      "9": 0,
      "10": 0,
      "11": 0,
      "12": 0
    };

    let resultFilteredByDepartments = {};

    this.shopDepartmentsValue.forEach(department => {
      resultFilteredByDepartments[department.name] = 0;
    });

    if (filter === "months") {
      const currentYear = new Date().getFullYear();
      data.forEach(product => {
        const date = new Date(product.soldAt);
        const month = date.getMonth() + 1;
        const productYear = date.getFullYear();
        if (productYear === currentYear) {
          const monthKey = month.toString();
          if (resultFilteredByMonths.hasOwnProperty(monthKey)) {
            resultFilteredByMonths[monthKey] += product.salePrice;
          }
        }
      });
      return this.changeKeyIntToMonth(resultFilteredByMonths);
    } else if (filter === "department") {
      data.forEach(product => {
        const departmentName = product.department.name;
        if (resultFilteredByDepartments.hasOwnProperty(departmentName)) {
          resultFilteredByDepartments[departmentName] += product.salePrice;
        }
      });
      return resultFilteredByDepartments;
    }
  }

  formatNumberWithSpaces(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  changeKeyIntToMonth(array) {
    let modifiedArray = {};

    for (const key in array) {
      switch (parseInt(key)) {
        case 1:
          modifiedArray["Janvier"] = array[key];
          break;
        case 2:
          modifiedArray["Février"] = array[key];
          break;
        case 3:
          modifiedArray["Mars"] = array[key];
          break;
        case 4:
          modifiedArray["Avril"] = array[key];
          break;
        case 5:
          modifiedArray["Mai"] = array[key];
          break;
        case 6:
          modifiedArray["Juin"] = array[key];
          break;
        case 7:
          modifiedArray["Juillet"] = array[key];
          break;
        case 8:
          modifiedArray["Août"] = array[key];
          break;
        case 9:
          modifiedArray["Septembre"] = array[key];
          break;
        case 10:
          modifiedArray["Octobre"] = array[key];
          break;
        case 11:
          modifiedArray["Novembre"] = array[key];
          break;
        case 12:
          modifiedArray["Décembre"] = array[key];
          break;
        default:
          break;
      }
    }
    return modifiedArray;
  }

  showModal() {
    document.querySelector("#labelModal").style.display = "block";
    let span = document.querySelector(".labels-modal .close");
    span.onclick = () => {
      document.getElementById("labelModal").style.display = "none";
    };
  }

  populateModal(labels, colors, values) {
    let labelsContainer = document.getElementById("labelsContainer");
    labelsContainer.innerHTML = "";
    labels.forEach((label, index) => {
      let labelItem = document.createElement("div");
      labelItem.classList.add("label-item");

      let colorBox = document.createElement("div");
      colorBox.classList.add("color-box");
      colorBox.style.backgroundColor = colors[index];

      let formattedValue = this.formatNumberWithSpaces(values[index]);

      let labelText = document.createElement("span");
      labelText.textContent = `${label}: ${formattedValue} €`;

      labelItem.appendChild(colorBox);
      labelItem.appendChild(labelText);
      labelsContainer.appendChild(labelItem);
    });
  }
}
