import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="appointment-form"
export default class extends Controller {
  static targets = ["pickupDatetime"]

  connect() {
  }

  submit(event) {
    event.preventDefault();
    let method;
    if(this.pickupDatetimeTarget.dataset.persisted === "true"){
       method = "PUT"
    }else{
       method = "POST"
       this.pickupDatetimeTarget.dataset.persisted = true
    }
    const options = {
      method: method,
      headers: {
        "Accept": "application/json",
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: new FormData(this.element)
    }
    fetch(this.element.action, options)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const paymentSubmitButton = document.querySelector("[data-cart-countdown-target='submitBtn']")
        if (data.order_can_complete) {
          paymentSubmitButton.removeAttribute('disabled')
          window.location.reload()
        } else {
          paymentSubmitButton.addAttribute('disabled', true)
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }
}
