import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comment-history"
export default class extends Controller {
  static targets = ["ticket"];

  static values = { ticketId: Number }

  connect() {
    let ticket = document.querySelector(`#ticket-content-${ticketIdValue}`)
    if(ticket.style.display == "none"){
      ticket.style.display = "block"
    } else if(ticket.style.display != "none"){
      ticket.style.display = "none"
    }
  }
}
