import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comment-history"
export default class extends Controller {
  static targets = ["history"];

  connect() {

  }

  toggle() {
    if (this.historyTarget.classList.contains("d-none")) {
      this.historyTarget.classList.remove("d-none");
      document.querySelector("#commentBox").classList.remove("d-none")
      document.querySelector("#historyButton").textContent = "Masquer l'historique des refus"
    } else {
      this.historyTarget.classList.add("d-none");
      document.querySelector("#commentBox").classList.add("d-none")
      document.querySelector("#historyButton").textContent = "Afficher l'historique des refus"

    }
  }
}
