import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password"
export default class extends Controller {
  connect() {
    const hint = this.element.querySelector('.form-text')
    this.element.insertAdjacentHTML('beforeend', '<i class="fa-regular fa-eye" data-action="click->password#toggle"></i>')
    const eye = this.element.querySelector('i')
    if (hint) {
      this.element.insertBefore(eye, hint)
    }
  }

  toggle(event) {
    event.currentTarget.classList.toggle('fa-eye');
    event.currentTarget.classList.toggle('fa-eye-slash');
    const input = event.currentTarget.parentElement.querySelector('input')
    input.type === 'password'? input.type = 'text' : input.type = 'password'
  }
}
