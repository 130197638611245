// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import "bootstrap";
// import LocalTime from "local-time"s
import { initLocalTime } from './packs/init_local_time';

// LocalTime.start()
initLocalTime();

const test = () => {
  window.addEventListener('load', () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/sw.js")
    }
  })
}

document.addEventListener('turbo:render', test());
