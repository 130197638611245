import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="loader"
export default class extends Controller {
  static targets = ["loader"]

  connect() {
  }

  showLoader() {
    this.loaderTarget.classList.remove("d-none");
  }
}
