import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-submit"
export default class extends Controller {
    static values = {
      timeout: { type: Number, default: 500 },
    }
  connect() {
  }

  submit() {
    console.log("submit");
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.element.requestSubmit()
    }, this.timeoutValue)
  }
}
