import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="user-cgv"
export default class extends Controller {
  static targets = ['cgv', 'button', 'modal']

  connect() {
  }

  cgvAcceptation(event) {
    if (event.target.checked) {
      const modal = new bootstrap.Modal(this.modalTarget)
      modal.show()
      this.buttonTarget.removeAttribute("disabled")
    } else {
      this.buttonTarget.setAttribute("disabled", true)
    }
  }
}
