import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";
import { French } from 'flatpickr/dist/l10n/fr.js'

// Connects to data-controller="datepicker"
export default class extends Controller {
  static values = {
    enableTime: { type: Boolean, default: false},
    submitOnClose: { type: Boolean, default: false},
    minDate: String,
    maxDate: String,
    minTime: String,
    maxTime: String
  }

  initialize() {
    this.config = {
      locale: French,
      altInput: true,
      time_24hr: true,
      dateFormat: "Z",
      altFormat: "d/m H:i"
    }
  }

  connect() {
    this.config = {
      ...this.config,
      enableTime: this.enableTimeValue,
      minDate: this.minDateValue,
      maxDate: this.maxDateValue,
      minTime: this.minTimeValue,
      maxTime: this.maxTimeValue
    }

    if (this.submitOnCloseValue) {
      this.config = {
        ...this.config,
        onClose: this.submitForm.bind(this)
      }
    }

    flatpickr(this.element, this.config)
  }

  submitForm() {
    this.element.closest("form").requestSubmit()
  }
}
